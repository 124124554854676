import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
        </div>
        <div id="page" className="pageFrontpage">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/">PT</a>
                      </li>
                      <li>
                        <a href="/it/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper">
              <div className="home-slider">
                <div className="areaInner">
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider_edit_disable{width:100%;min-height:20px;background:#999999;padding:10px;text-align:center;color:#fff}\n                        .flexslider_edit_disable.error{color:#cf0000}\n                        a:focus{outline:none!important;}\n                     "
                    }}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".flexslider-container4169 {margin:0 auto;position:relative;\t}.flexslider-container4169 img {max-height:450px;}.flexslider-container4169 li {margin-right:0px} .flexslider-container4169 {padding:0px;padding-right:0px;} .flexslider-container4169 img {max-width:100%;}\n                        .flexslider-container4169 {margin:0 auto 0px auto}\n                        .flexslider-container4169 h1 {margin:0;font-size:35px;font-weight:normal;text-align:left;}\n                     "
                    }}
                  />
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html:
                        ' $(window).on(\'load\', function() {$(".flexslider-container4169").flexslider({animation:"slide",direction:"horizontal",reverse:false,animationLoop:true,startAt:0,slideshow :true,slideshowSpeed :7000,animationSpeed :600,initDelay :0,randomize :false,pauseOnAction :false,pauseOnHover :true,smoothHeight :false,useCSS :true,touch :true,video :true,controlNav :true,directionNav :false,keyboard :true,multipleKeyboard :true,mousewheel :false,pausePlay :false,itemMargin:0,minItems:1,maxItems :1,itemWidth: 1680,\nnamespace: "flex-",\nstart: function(){\n$(".flexslider-img-preloader").css("background-image","none");\n$(".flexslider-img-content").css("opacity","1");\n$(".flexslider-img-content").css("visibility","visible");\n}\n});\n});\n'
                    }}
                  />
                  <div className="external-flexslider-container4169 flexslider-img-preloader">
                    <div className="default-flexslider flexslider-container4169 flexslider-img-content">
                      <ul className="slides4169 default-flex-slides slides">
                        <li>
                          <a href="/es/nuestros-productos/hydro-infusion/">
                            {" "}
                            <img src="/assets/images/Banner-Hidroinfusion-Homepage.png" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                <br />
                                RÁPIDA ABSORCIÓN Y<br /> SENSACIÓN REFRESCANTE{" "}
                                <br />
                                AL INSTANTE
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                CON PIZ BUIN HYDRO INFUSION SUN GEL CREAM
                                <sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/es/nuestros-productos/hydro-infusion/"
                              >
                                Leer más
                              </a>
                            </div>
                          </div>
                        </li>
                        {/* <li>
                          <a href="/es/nuestros-productos/active-protect/">
                            {" "}
                            <img src="/assets/images/2019-banner-travel-size-home.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                <br />
                                HIDRATACIÓN DURADERA
                                <br /> E INTENSA <br />
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                PIZ BUIN ACTIVE &amp; PROTECT<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/es/nuestros-productos/active-protect/"
                              >
                                Leer más
                              </a>
                            </div>
                          </div>
                        </li> */}
                        {/* <li>
                          <a href="/es/nuestros-productos/instantglow/">
                            {" "}
                            <img src="/assets/images/2019-instant-glow-home.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                PROTECCIÓN Y BELLEZA
                                <br /> AL INSTANTE Y DURANTE <br />
                                TODO EL VERANO
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN INSTANT GLOW<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/es/nuestros-productos/instantglow/"
                              >
                                Leer más
                              </a>
                            </div>
                          </div>
                        </li> */}
                        <li>
                          <a href="/es/nuestros-productos/tan-protect/">
                            {" "}
                            <img src="/assets/images/slider-2020-tan-protect.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                Realza el bronceado natural
                              </h3>
                              <p
                                style={{
                                  color: "#f78f1e !important"
                                }}
                              >
                                PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                              </p>
                              <a
                                className="btn"
                                target="_blank"
                                href="/es/nuestros-productos/tan-protect/"
                              >
                                Leer más
                              </a>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="/es/nuestros-productos/allergy/">
                            {" "}
                            <img src="/assets/images/Banner-Allergy-ProductPage.jpg" />
                          </a>
                          <div className="flex-caption-wrapper">
                            <div className="flex-caption">
                              <h3
                                style={{
                                  color: "!important"
                                }}
                              >
                                <br />
                                Protección para la piel sensible al sol
                              </h3>
                              <p
                                style={{
                                  color: "!important"
                                }}
                              >
                                PIZ BUIN<sup>®</sup> ALLERGY
                              </p>
                              <a
                                className="btn"
                                href="/es/nuestros-productos/allergy/"
                              >
                                Lee más
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    style={{
                      clear: "both"
                    }}
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="innerContent clearfix">
                <div className="section">
                  <figure>
                    <a href="/es/nuestros-productos/galeria/">
                      <img src="/assets/images/Products taco.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/es/nuestros-productos/galeria/">
                        PROTECCIÓN SOLAR ADAPTADA A CADA NECESIDAD
                      </a>
                    </h3>
                    <h4>
                      <a href="/es/nuestros-productos/galeria/">
                        Explora la gama completa de productos PIZ BUIN
                        <sup>®</sup>
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/es/conozca-el-sol/">
                      <img src="/assets/images/CallToAction_640x370px_KnowTheSun.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/es/conozca-el-sol/">CONOZCA EL SOL</a>
                    </h3>
                    <h4>
                      <a href="/es/conozca-el-sol/">
                        Nuestra guía para disfrutar del sol de forma saludable
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
                <div className="section">
                  <figure>
                    <a href="/es/nuestra-historia/">
                      <img src="/assets/images/mobile_landing_OurHeritage_x2.jpg" />
                    </a>
                  </figure>
                  <div className="titleBlock">
                    <h3>
                      <a href="/es/nuestra-historia/">NUESTRA HISTORIA</a>
                    </h3>
                    <h4>
                      <a href="/es/nuestra-historia/">
                        Especialistas en proteger tu piel durante más de 70 años
                      </a>
                    </h4>
                  </div>
                  <div className="shadow">
                    <br />
                  </div>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.min.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
       
        
      </div>
    );
  }
}

export default Page;
